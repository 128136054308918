.right-panel-container {
  width: 34%;

  h2 {
    margin-top: 0; } }

.help {
  text-align: center;
  padding-top: 4rem;

  i {
    color: rgb(211,215,104);
    font-size: 2rem;
    margin-right: 0.5rem; }

  .d-inline-flex {
    align-items: center; } }

.right-panel-scrollable {
  overflow: auto;
  max-height: 100%; }

.invoice {
  background-color: $color-grey;
  padding: 1rem; }

.password-reset {
  i.big-icon {
    font-size: 4rem;
    color: $color-button-primary; } }

.mobile-text-content {
  @include respondMax($mobile-width) {
    padding-left: 1rem;
    padding-right: 1rem; } }

.thank-you {

  @include respondMax($mobile-width) {
    padding-left: 1rem;
    padding-right: 1rem; }

  h1::after {
    display: block;
    content: "";
    height: 1px;
    background-color: $color-grey;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-right: 1rem; }

  .btn {
    font-size: 0.9rem;
    font-weight: normal; }

  h2 {
    font-weight: bold; }
  i.big-icon {
    font-size: 4rem;
    color: $color-button-primary;

    &.error {
      color: $color-red; }

    &.info {
      color: $color-badge-new; } } }

