.form-section.account-page {
  padding-left: 0;
  margin-right: 1rem;
  padding-bottom: 2rem;
  padding-right: 0;
  background-color: $color-account-page-background;

  .form {
    max-width: 400px;
    padding-left: 1rem; }

  &::before {
    display: block;
    content: "";
    height: 1px;
    background-color: $color-grey; } }
