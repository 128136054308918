ul.line-separated {
  li:not(.no-separator) {
    padding: 0;

    &::after {
      content: "";
      display: inline-block;
      width: 1px;
      height: 1rem;
      background-color: $color-white;
      margin-left: 6px;
      // margin-right: 2px
      margin-bottom: -2px; } } }
