footer {
  background-color: $color-black;
  background-size: cover;
  color: $color-white;
  margin-top: 5rem;
  font-size: 0.8rem;

  &::before {
    content: "";
    display: block;
    height: 13px;
    background-color: $color-theme; }

  a {
    color: $color-white; }

  .content {
    padding-top: 5rem;
    padding-bottom: 5rem; }

  .footer-bottom {
    div {
      width: 33%;
      vertical-align: middle; }

    &::before {
      display: block;
      height: 1px;
      content: "";
      background-color: #485054;
      margin-bottom: 1rem; }

    .circle {
      position: relative;
      width: 30px;
      height: 30px;
      display: inline-block;
      border-radius: 50%;
      background-color: white;
      color: $color-black;
      margin: 0.2rem;
      font-size: 1rem;

      &:hover {
        color: $color-white;
        background-color: $color-theme; }

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); } } }

  .footer-top {
    margin-bottom: 2rem; }

  .footer-logo-container {
    max-width: 300px;
    display: inline-block;

    img {
      margin-top: -35px;
      margin-left: -25px; } }

  .footer-table-container {
    display: inline-block;
    vertical-align: top;

    th {
      font-weight: normal;
      font-size: 0.9rem;
      padding-bottom: 1.5rem;
      padding-left: 2.5rem; }

    td {
      padding-left: 2.5rem; } } }
