.product-list-container {
  width: 66%;

  &--wide {
    width: 100%; }

  display: inline-block;

  @include respondMax($mobile-width) {
    width: 100%; }

  // &::after
  //   display: block
  //   content: ""
  //   background-color: $color-light-grey
  //   width: 1px
  //   height: 100%
  //   position: absolute
  //   right: 0
  //   top: 0

  ul {
    padding-left: 0;
    margin-right: 2rem;

    @include respondMax($mobile-width) {
      margin-right: 0;
      padding-right: 1rem;
      padding-left: 1rem; }

    &.grid {
      display: flex;
      flex-wrap: wrap;
      // align-items: stretch

      li {
        width: 50%;
        // display: flex
        // flex-direction: column

        &:not(:last-child)::after {
          display: none; }

        .product-list-item {
          // display: block
          display: flex;
          align-items: center;
          flex-direction: column;
          margin-left: 0.5rem;

          .product-info {
            height: 40px; }

          h2 {
            height: 80px; }

          .preview-container {
            height: 300px;

            .preview {
              height: 90%; } }

          .product-content {
            margin-left: 0;

            .make-order {
              display: block;
              float: unset;

              .btn {
                display: block;
                margin-left: 0;
                margin-top: 1rem;
                width: 100%; } }

            // .product-content-row
            //   display:

            //   .d-inline-block
 } } } }            //     display: block

    li {
      flex: 0 0 50%;
      display: block;
      &:not(:last-child)::after {
        display: block;
        content: "";
        background-color: $color-light-grey;
        width: 100%;
        height: 1px; } } } }

.product-detail-web-content {
  padding: 1rem;

  ul {
    padding-left: 1rem;
    li {
      display: list-item;

      &::after {
        display: none !important; } } } }


.mobile-product-detail {
  .preview-container {
    padding: 0 !important;
    text-align: center; } }

.mobile-make-order {
  .btn {
    display: block;
    margin-left: 0;
    margin-top: 1rem;
    width: 100%; } }

.product-list-item {
  display: flex;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  @include respondMax($mobile-width) {
    display: block; }

  h2 {
    margin-top: 0;
    margin-bottom: 4px; }

  .preview-container {
    display: inline-block;

    // .preview
 }    //// max-height: 100px

  .product-content {
    display: inline-block;
    margin-left: 2rem;
    width: 100%;

    .badge {
      margin-right: 1rem;
      font-size: 0.9rem;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem; } }

  .product-content-row {
    margin-top: 1rem;
    margin-bottom: 1rem; } }

.product-info {
  font-size: 0.8rem;

  span {
    margin-right: 1rem;
    white-space: nowrap;
    display: inline-block;

    i {
      margin-right: 2px; } } }

.preview-big {
  cursor: zoom-in; }

.preview-zoom {
  cursor: zoom-out; }

.appearance-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 2rem;

  .appearance-label {
    margin-right: 0.5rem; }

  .appearance-switch {
    display: flex;
    align-items: stretch;

    .appearance-switch-item {
      width: 22px;
      text-align: center;
      margin: 0.2rem;
      padding: 0.5rem;
      background-color: $color-grey;
      border-radius: 4px;
      cursor: pointer;

      &.is-active {
        background-color: $color-grey-darkest; } } } }

