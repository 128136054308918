.cart-content {
  .order-box-item-row-content {
    .w-80 {
      width: 100%; } }

  .order-box-content {
    .cart-sum {
      @include respondMin($mobile-width) {
        max-width: 40%;
        margin-left: auto; } } }

  .cart-order-info {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 2rem;
    background-color: $color-grey;

    i {
      margin-right: 0.5rem; }

    .print-page-btn {
      position: absolute;
      right: 1rem;
      font-size: 1.5rem; } } }
