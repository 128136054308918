html {
  scroll-behavior: smooth; }

body {
  margin: 0;
  color: $color-black;
  background-color: $color-white;
  font-family: 'Open Sans'; }

a {
  color: $color-black;
  text-decoration: none;

  &:hover {
    text-decoration: underline; } }

h1 {
  color: $color-h1;
  font-size: 1.5rem;
  font-weight: normal;

  @include respondMax($mobile-width) {
    text-align: center;
    padding-bottom: 1rem;
    padding-top: 1rem; } }

h2 {
  font-size: 1.2rem;
  font-weight: normal; }

.bold {
  font-weight: bold !important; }

.page {
  margin: auto; }

.content {
  max-width: 1355px;
  margin: auto;
  padding-left: 1rem;
  padding-right: 1rem;

  &--mobile-wide {
    @include respondMax($mobile-width) {
      padding: 0; } } }

.flex-1 {
  flex: 1; }

.d-inline-block {
  display: inline-block; }

.d-flex {
  display: flex; }

.d-flex-space-between {
  display: flex;
  justify-content: space-between; }

.d-inline {
  display: inline; }

.d-inline-flex {
  display: inline-flex !important; }

.d-block {
  display: block !important; }

.d-none {
  display: none !important; }

.m-d-block {
  @include respondMax($mobile-width) {
    display: block !important; } }

.m-text-center {
  @include respondMax($mobile-width) {
    text-align: center !important; } }

.m-text-left {
  @include respondMax($mobile-width) {
    text-align: left !important; } }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.float-right {
  float: right; }

.float-left {
  float: left; }

.position-relative {
  position: relative; }

.position-absolute {
  position: absolute; }

.m-auto {
  margin: auto; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 1rem; }

.mb-1 {
  margin-bottom: 1rem; }

.mb-2 {
  margin-bottom: 2rem; }

.mb-4 {
  margin-bottom: 4rem; }

.mb-decent {
  margin-bottom: 4px; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 1rem; }

.mt-2 {
  margin-top: 2rem; }

.mt-5 {
  margin-top: 5rem; }

.pb-1 {
  padding-bottom: 1rem; }

.pb-2 {
  padding-bottom: 2rem; }

.m-mr-0 {
  margin-right: 0 !important; }

.mr-0 {
  margin-right: 0 !important; }

.mr-1 {
  margin-right: 1rem; }

.mr-2 {
  margin-right: 2rem !important; }

.mr-decent {
  margin-right: 6px; }

.ml-0 {
  margin-left: 0 !important; }

.ml-1 {
  margin-left: 1rem; }

.ml-m-1 {
  @include respondMax($mobile-width) {
    margin-left: 1rem; } }

.ml-decent {
  margin-left: 6px; }

.ml-auto {
  margin-left: auto !important; }

.mt-auto {
  margin-top: auto; }

.mb-auto {
  margin-bottom: auto; }

.va-middle {
  vertical-align: middle; }

.uppercase {
  text-transform: uppercase; }

i.danger {
  color: $color-red; }

.grey-background {
  background-color: $color-light-grey; }

.rounded {
  border-radius: 4px; }

.border-simple {
  border-style: solid;
  border-width: 1px;
  border-color: $color-grey;

  &--top {
    border-width: 1px 0 0 0; }

  &--left {
    border-width: 0 0 0 1px; } }

.w-100 {
  width: 100%; }

.m-h-100 {
  @include respondMax($mobile-width) {
    height: 100%; } }

.m-w-100 {
  @include respondMax($mobile-width) {
    width: 100% !important; } }

.w-80 {
  width: 80%; }

.w-50 {
  width: 50%; }

.p-1 {
  padding: 0.5rem; }

.p-2 {
  padding: 1rem; }

.pt-1 {
  padding-top: 1rem; }

.pr-decent {
  padding-right: 6px; }

.pr-1 {
  padding-right: 0.5rem; }

.pl-0 {
  padding-left: 0 !important; }

.pl-1 {
  padding-left: 0.5rem; }

.pl-2 {
  padding-left: 1rem; }

.centered {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

.clickable {
  cursor: pointer; }

.link {
  cursor: pointer;

  &--underline {
    text-decoration: underline; }

  &:hover {
    text-decoration: underline; } }

.topleft {
  position: absolute;
  top: 10px;
  left: 10px; }

.line-after::after {
  display: block;
  height: 1px;
  background-color: $color-grey;
  content: ""; }

.red {
  color: $color-red; }

.va-top {
  vertical-align: top; }

.hor-line-after {
  &::after {
    display: block;
    height: 1px;
    background-color: $color-grey;
    content: ""; }

  &--mt-2::after {
    margin-top: 2rem; } }

.color-grey {
  color: $color-grey-darkest; }

.font-smaller {
  font-size: 0.9rem; }
