.alert {
  background-color: $color-white;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;

  .close {
    right: 8px;
    top: 4px;
    cursor: pointer; }

  &--error {
    background-color: $color-red-light;
    color: $color-red-dark;
    border-color: #FFD6D6;
    border-width: 1px;
    border-style: solid; } }
