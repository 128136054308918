.form-section {
  padding-right: 2rem;

  @include respondMax($mobile-width) {
    padding-right: 0; }

  label {
    font-size: 0.8rem; }

  .input-group {
    display: block;
    margin-bottom: 1rem;
    margin-top: 4px;
    background-color: $color-grey;
    border-width: 0px;

    input {
      background-color: $color-grey;
      border-width: 0px; }

    textarea {
      background-color: $color-grey; } }

  &::before {
    display: block;
    content: "";
    height: 1px;
    background-color: $color-grey;
    margin-bottom: 3rem;
    margin-top: 3rem; } }

.input-group.with-validate-error {
  background-color: $color-red-light;
  input {
    background-color: $color-red-light; }

  .input-right-icon {
    right: 28px; } }

.input-group.with-validate-success {
  background-color: $color-green-light;
  input {
    background-color: $color-green-light; }

  .input-right-icon {
    right: 28px; } }

.form-select {
  .validate-error {
    margin-top: 0;
    margin-left: 4px; } }

.validate-error {
  color: $color-red;
  font-size: 0.8rem;
  margin-top: -10px;
  margin-bottom: 1rem; }

.input-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  color: $color-red;
  font-size: 0.8rem;
  &--success {
    color: $color-green; } }

.input-group {
  position: relative; }

.validate-select {
  position: relative; }

.input-right-icon {
  position: absolute;
  right: 8px;
  top: 4px;
  color: $color-grey-dark; }

.checkbox {
  cursor: pointer;
  width: 16px;
  height: 16px;
  border-style: solid;
  border-width: 1px;
  border-color: $color-grey-dark;
  border-radius: 2px;
  margin-right: 0.5rem;
  margin-top: 2px;
  font-size: 0.7rem;
  position: relative;
  display: inline-table;

  &--checked {
    border-color: $color-black; }

  i {
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(20%, 20%); } }

