@media not print {
  .no-print-hide {
    display: none !important; } }

@media print {
  body {
    font-size: 0.7rem;
    -webkit-print-color-adjust: exact; }

  .cookie-consent {
    display: none; }

  .app-logo {
    max-width: 10rem; }

  .print-hide {
    display: none !important; }

  .print-content-header {
    display: flex;
    align-items: center;
    justify-content: space-between; }

  .order-amount-button {
    display: none; }

  .order-box-summary {
    padding-top: 0;
    padding-bottom: 0; }

  .help {
    display: flex;
    font-size: 0.6rem;
    padding-top: 0;

    i {
      font-size: 1rem; }

    > * {
      margin-right: 2rem; } }

  .cart-content {
    .cart-order-info {
      background-color: white;
      padding-left: 0;
      padding-right: 0;
      padding-top: 1rem;
      padding-bottom: 1rem;
      display: flex;
      flex-wrap: wrap;

      > * {
        flex-basis: 50%; }

      h1 {
        display: none; } } }

  header {
    display: none; }

  header::after {
    display: none; }

  .navigation {
    display: none; }

  .menu-middle {
    display: none; }

  .menu-top {
    display: none; }

  .menu-left {
    width: unset; }

  .menu-bottom {
    display: none; }

  .btn {
    display: none !important; }

  .order-box-item-row-content {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem; }

  .order-box {
    border-width: 0;
    background-color: white;

    .fa-info-circle {
      display: none; }

    .order-item-content {
      margin-top: 0; }

    .order-box-item-row--grey {
      background-color: white; }

    .preview-container {
      .preview {
        height: 50px; } } }

  .clickable {
    display: none; }

  footer {
    display: none; } }
