.register-form {
  border-style: solid;
  border-width: 1px;
  border-color: $color-grey;
  border-radius: 4px;
  margin-right: 2rem;

  @include respondMax($mobile-width) {
    margin-right: 0; }

  .register-form-content {
    padding: 2rem; }

  .form-section {
    &::before {
      display: none; } }

  h2 {
    margin-top: 0; }

  .register-form-header {
    // background-image: url('../images/backgrounds/register.jpg')
    background-size: cover;
    padding-bottom: 3rem;
    background-position: center;
    border-radius: 4px 4px 0 0; } }

.register-content {
  max-width: 40%;
  display: flex;

  .register-check {
    margin-right: 0.5rem; } }
