@mixin respond($from: false, $to: false) {
    @if ($from and $to) {
        @media (min-width: $from / $base-font-size * 1em) and (max-width: $to / $base-font-size * 1em) {
            @content;
        }
    } @else if ($from) {
        @media (min-width: $from / $base-font-size * 1em) {
            @content;
        }
    } @else if ($to) {
        @media (max-width: $to / $base-font-size * 1em) {
            @content;
        }
    }
}

@mixin respondMin($from) {
    @include respond($from) {
        @content;
    }
}

@mixin respondMax($to) {
    @include respond(false, $to) {
        @content;
    }
}

@mixin respondMobileM($only: false) {
    @if ($only) {
        $only: 425 - 1;
    }

    @include respond(375, $only) {
        @content;
    }
}

@mixin respondMobileL($only: false) {
    @if ($only) {
        $only: 768 - 1;
    }

    @include respond(425, $only) {
        @content;
    }
}

@mixin respondTablet($only: false) {
    @if ($only) {
        $only: 1025 - 1;
    }

    @include respond(768, $only) {
        @content;
    }
}

@mixin respondDesktop($only: false) {
    @if ($only) {
        $only: 1200 - 1;
    }

    @include respond(1025, $only) {
        @content;
    }
}

@mixin respondDesktopM($only: false) {
    @if ($only) {
        $only: 1440 - 1;
    }

    @include respond(1200, $only) {
        @content;
    }
}

@mixin respondDesktopL($only: false) {
    @if ($only) {
        $only: 1600 - 1;
    }

    @include respond(1440, $only) {
        @content;
    }
}

@mixin respondDesktopXL($only: false) {
    @if ($only) {
        $only: 1920 - 1;
    }

    @include respond(1600, $only) {
        @content;
    }
}

@mixin respondDesktopXXL($only: false) {
    @if ($only) {
        $only: 2560 - 1;
    }

    @include respond(1920, $only) {
        @content;
    }
}
