.mobile-order-panel {
  text-align: right;

  span {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }

  &::after {
    display: block;
    content: "";
    height: 1px;
    background-color: $color-grey; }

  .mobile-order-panel-content {
    padding: 1rem;
    color: $color-theme; } }
