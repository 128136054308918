.app-logo {
  left: 0;
  max-width: $logo-max-width; }

.footer-logo {
  max-width: $logo-max-width; }

.order-box {
  .preview-container {
    width: 90px;
    text-align: center;
    padding-top: 4px;
    .preview {
      height: 60px; } } }

img.preview--detail {
  max-height: 250px;
  width: auto; }

.carousel {
  position: relative;
  // width: 90vw

  &.preview--detail {
    width: 360px;

    @include respondMax(370) {
      width: unset; } }

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    // height: 100%
    // width: 100%
    transform: translate(-50%, -50%); }

  @include respondMax($mobile-width) {
    width: 100%; }

  .carousel-button {
    position: absolute;
    top: 50%;
    border-width: 0;
    background-color: $color-white;
    font-size: 2rem;
    color: $color-grey-darkest;
    transform: translateY(-50%);

    &:disabled {
      display: none; }

    &--back {
      left: 0; }

    &--next {
      right: 0; } } }

