.btn {
  background-color: $color-button-primary;
  padding: 10px;
  border-style: none;
  border-radius: 4px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  color: $color-black;
  cursor: pointer;
  font-weight: bold;

  &.is-disabled {
    background-color: $color-grey;
    cursor: not-allowed;

    &:hover {
      background-color: $color-grey; } }

  &--no-reg {
    font-size: 0.8rem; }

  &--secondary {
    background-color: $color-button-secondary; }

  &--white {
    background-color: $color-white; }

  &--not-active {
      background-color: $color-grey-dark; }

  &:hover {
    background-color: $color-button-primary-selected; } }

a.btn:hover {
  text-decoration: none; }
