// moved to variables
$color-green: var(--color-green);//#CDD24F
$color-green-light: var(--color-green-light);//#F8F8E5
$color-white: var(--color-white);//#FFFFFF
$color-black: var(--color-black);//#283237
$color-badge: var(--color-badge);//#F0AE50
$color-badge-new: var(--color-badge-new);//#50C4F0
$color-focus: var(--color-focus);//#decc57
$color-button-secondary: var(--color-button-secondary);//#F5F5F5
$color-button-primary: var(--color-green);
$color-button-primary-selected: var(--color-button-primary-selected);//#E0E825
$color-light-grey: var(--color-light-grey);//#F4F4F4
$color-grey-yellow: var(--color-grey-yellow);//#FCFCFC
$color-grey: var(--color-grey);//#F5F5F5
$color-grey-darker: var(--color-grey-darker);//#E9E9E9
$color-grey-dark: var(--color-grey-dark);//#D5D5D5
$color-grey-darkest: var(--color-grey-darkest);//#B7B7B7
$color-red: var(--color-red);//#CC4444
$color-red-light: var(--color-red-light);//#F7E3E3
$color-red-dark: var(--color-red-dark);//#721C24
$color-h1: var(--color-h1);//#293237
$color-account-page-background: var(--color-account-page-background);//#FBFBFB
$color-theme: var(--color-theme);
$color-theme-filter: var(--color-theme-filter);
