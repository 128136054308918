.product-list-container {
  .navigation {
    font-size: 0.8rem;

    i {
      font-size: 1rem; }

    li {
      display: inline-block;

      &:not(:last-child)::after {
        display: inline;
        content: ">";
        background-color: $color-white;
        width: unset;
        height: unset;
        padding-left: 1rem;
        padding-right: 1rem; } } } }
