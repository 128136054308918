// .loader
//   border: 16px solid rgba(0,0,0,0)
//   border-top: 16px solid $color-black
//   border-radius: 50%
//   width: 60px
//   height: 60px
//   animation: spin 2s linear infinite

// @keyframes spin
//   0%
//     transform: rotate(0deg)
//   100%
//     transform: rotate(360deg)

.loader-back {
  width: 1rem;
  height: 1rem;
  position: absolute; }

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 1em;
  height: 1em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out; }

.loader {
  color: #73152E;
  color: $color-theme;
  font-size: 10px;
  // margin: 80px auto
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s; }

.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0; }

.loader:before {
  left: -1em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s; }

.loader:after {
  left: 1em; }

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em; }

  40% {
    box-shadow: 0 2.5em 0 0; } }

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em; }

  40% {
    box-shadow: 0 2.5em 0 0; } }

