.order-box {
  min-height: 300px;
  background-color: $color-grey;
  border-style: solid;
  border-width: 0 1px 1px 1px;
  border-color: $color-grey-darker;

  h2 {
    margin-left: 2rem;
    padding-top: 1rem; }

  h3 {
    margin-left: 2rem; }

  .order-item-content {
    margin-top: 1rem;

    .cancel-order {
      position: absolute;
      right: 0;
      top: 0;
      color: $color-grey-darker; } }

  .preview-text {
    padding-left: 0.5rem;
    padding-bottom: 1.5rem; }

  table {
    width: 100%; } }

.order-box-item-time {
  background-color: $color-grey-darker;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;

  .fa-times {
    color: $color-grey-darkest; } }

.order-box-item-row-content {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem; }

.order-box-item-row {
  background-color: $color-white;

  &--grey {
    background-color: $color-grey;

    .preview-text {
      padding-bottom: 0; } }

  &:after {
    content: "";
    display: block;
    height: 1px;
    background-color: $color-grey-darker; } }

.fill-bar {
  height: 10px;
  width: 100%;
  background-color: $color-white;
  border-radius: 4px;

  .fill-bar-percent {
    height: 100%;
    background-color: #BD909D;
    border-radius: 4px; } }

.order-amount-changer {
  margin-left: 0.5rem;

  @include respondMax($mobile-width) {
    margin-left: 0; } }

.order-amount-button {
  cursor: pointer;
  display: inline-block;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-style: solid;
  border-radius: 4px;
  border-width: 1px;
  border-color: $color-grey-darker;
  user-select: none;

  &.is-disabled {
    background-color: $color-grey;
    cursor: not-allowed; } }

.order-amount-number {
  display: inline;
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.order-box-summary {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;

  .btn {
    margin-left: 0;
    width: 100%; } }

.empty-order {
  color: $color-grey-dark;
  text-align: center;
  display: flex;

  .empty-order-icon {
    font-size: 3rem;
    margin-right: 1rem; }

  .empty-order-content {
    text-align: left;
    padding-top: 0.5rem; } }
