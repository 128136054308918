.login-page {
  background-size: cover;
  background-position: right;
  height: 100vh;

  h1 {
    font-weight: bold;
    color: $color-theme;
    display: inline;
    vertical-align: middle; }

  .heading {
    margin-bottom: 2rem;
    margin-top: 2rem;
    img {
      max-width: 150px;
      vertical-align: middle; } }

  .dialog-content {
    min-width: 300px;
    max-width: 600px;
    margin: auto; }

  .btn {
    margin-left: 0;
    display: block;
    margin-right: 0;
    margin-top: 1rem; }

  .remember-me {
    display: inline;

    label {
      color: $color-grey-darker;
      margin-left: 4px;
      margin-right: 1rem;

      &.is-selected {
        color: $color-black; } } }

  .register {
    margin-top: 1rem;

    .text {
      font-size: 0.8rem; }

    .link {
      text-decoration: underline;
      font-weight: bold;
      font-size: 0.8rem; } }

  .select-language {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 80px; } }

.input-group {
  &--login {
    display: block;
    margin-top: 4px; } }

.login-contact {
  font-size: 0.7rem;
  text-decoration: underline;
  font-weight: bold; }

.login-switch-env {
  font-size: 0.8rem;
  background-color: $color-white;
  padding: 10px;
  border-radius: 4px;
  position: absolute;
  left: 10px;
  top: 10px; }
