.table {
  border-width: 1px;
  border-color: $color-grey;
  border-style: solid;

  .table-head {
    background-color: $color-grey;
    display: flex;

    @include respondMax($mobile-width) {
      display: block; }

    .table-head-actions {
      flex: 1;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @include respondMax($mobile-width) {
        padding-bottom: 1rem; }

      .table-head-action {
        margin-right: 1rem; }

      .button-back {
        display: inline-block;
        background-color: $color-grey-darker;
        padding: 0.5rem;
        border-radius: 4px; } } }

  .link {
    color: $color-theme; }

  .table-content {
    padding: 1rem;

    .table-content-item {
      width: 10rem; } }

  h1 {
    padding-left: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin: 0; }

  table {
    background-color: $color-grey;
    width: 100%;
    text-align: left;
    border-spacing: 0px 1px;

    th {
      padding: 1rem; }

    td {
      padding: 1rem; }

    tbody {
      background-color: $color-white; } }

  .table-mobile-item {
    margin-bottom: 2rem;
    margin-top: 2rem;

    .table-mobile-cell {
      padding: 1rem;
      display: flex;

      .table-mobile-cell-left {
        width: 50%; }

      .table-mobile-cell-right {
        width: 50%; } } } }

.paginator {
  margin: 1rem;
  display: flex;
  justify-content: center;

  .paginator-item {
    background-color: $color-grey-darker;
    border-radius: 50%;
    margin: 1rem;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &--active {
      background-color: $color-theme;
      color: $color-white;
      cursor: not-allowed; }

    &--fake {
      cursor: unset;
      background-color: $color-grey; } } }
