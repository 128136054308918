.badge {
  border-radius: 4px;
  background-color: $color-badge;
  color: $color-white;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;

  &--allergen {
    background-color: $color-grey-dark;
    display: inline-block; }

  &--new {
    background-color: $color-badge-new; } }
