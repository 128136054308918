header.mobile {
  .input-search {
    position: relative;

    input {
      min-width: unset;
      background-color: $color-white; }

    .input-append {
      background-color: $color-white;

      .input-append-text {
        display: none; } } } }

.submenu {
  @include respondMax($mobile-width) {
    background-color: $color-white;

    .submenu-item {
      display: flex;
      width: 100%;
      padding-top: 1rem;
      padding-bottom: 1rem;
      font-size: 0.9rem;

      img {
        margin-right: 1rem;
        vertical-align: middle;
        margin-top: -8px; } } } }

.menu-top {
  &--mobile {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;

    .tiles {
      padding-top: 10px;
      padding-bottom: 10px; }

    .tile {
      font-size: 2rem;
      display: inline;
      padding: 1rem; }

    .tiles--right {
      position: absolute;
      right: 0;
      top: 0; }

    .tiles--middle {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%); }

    .top-menu-submenu {
      background-color: $color-white;
      color: $color-black;
      font-size: 1rem;
      position: absolute;
      z-index: 999;
      width: 100%;
      box-shadow: 0px 2px 10px $color-grey-darkest;

      a {
        color: $color-black;
        margin-left: 1rem; }

      .d-block {
        display: inline !important; }

      .item {
        &::after {
          display: block;
          height: 1px;
          content: "";
          background-color: $color-grey; }

        .item-content {
          padding: 1rem; } } }

    .language-changer {
      display: inline-block;

      i {
        margin-left: 1rem; }

      .language-changer-item {
        text-align: left;
        margin-top: 10px;

        div {
          padding-top: 0.5rem; } } } } }

.mobile-submenu-container {
  background-color: $color-grey;

  .mobile-top-filter-item {
    padding: 1rem;
    font-weight: bold;

    &.opened {
      background-color: $color-white; }

    &--subitem {
      background-color: $color-white;
      font-weight: normal;
      font-size: 0.9rem; }

    &.is-selected {
      font-weight: bold;
      color: $color-theme;
      a {
        color: $color-theme; }
      img {
        filter: $color-theme-filter; } }

    .top-filter-item {
      img {
        margin-right: 1rem; }
      span {
        vertical-align: super; }

      i {
        display: inline;
        padding-top: 0.5rem;
        float: right; } } } }

.menu-middle {
  &--mobile {
    .input-group {
      input {
        border-radius: 0; } } } }
