.form-group {
  display: inline-block;
  margin: 1rem;
  text-align: left;

  label {
    display: block;
    padding-bottom: 4px; } }

.input-group {
  // background-color: $color-white
  // color: $color-black
  display: inline-block;
  border-radius: 4px;
  // border-style: solid
  // border-color: $color-grey-dark
  // border-width: 1px

  .input-append {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;
    background-color: $color-grey-darker;
    border-radius: 0px 4px 4px 0px;

    @include respondMax($mobile-width) {
      padding-top: 14px;
      padding-bottom: 14px; } }

  .input-prepend {
    background-color: $color-grey-darker;
    padding-top: 8px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 8px;

    @include respondMax($mobile-width) {
      padding-top: 14px;
      padding-bottom: 14px; } }



  textarea {
    border-style: none;
    color: $color-black;
    border-radius: 4px;
    display: block;
    width: 100%;
    resize: vertical; }

  input {
    border-style: none;
    border-radius: 4px;
    color: $color-black;
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;
    box-sizing: border-box;
    border-radius: 4px;
    border-style: solid;
    border-color: $color-grey-dark;
    border-width: 1px;

    &:focus-within {
      box-shadow: 0 0 0 1pt $color-focus; }

    @include respondMax($mobile-width) {
      padding-top: 14px;
      padding-bottom: 14px; }

    &:focus {
      outline: none; } } }

input.amount-input {
  max-width: 40px;
  margin-right: 0.3rem; }

.hint {
  font-size: 0.8rem;
  margin-top: -14px;
  margin-bottom: 1rem;
  color: $color-grey-darkest;

  .hint-option {
    text-decoration: underline;
    margin-left: 1rem;
    cursor: pointer; } }


.input-calendar-select-wrapper {
  position: relative;

  .input-calendar-option {
    position: absolute;
    z-index: 999; }

  .calendar-icon-wrapper {
    padding-right: 2px;
    display: flex;
    color: $color-grey-dark; }

  .calendar-icon {
    padding-left: 10px;
    padding-right: 2px; }

  .separator {
    width: 1px;
    background-color: $color-grey-dark;
    box-sizing: border-box;
    align-self: stretch; }

  .input-calendar-select {
    border-style: solid;
    border-radius: 4px;
    border-color: $color-grey-dark;
    border-width: 1px;
    padding: 0.5rem;

    &:hover {
      border-color: $color-grey-darkest; } } }
