.history-table-head {
  display: flex;
  align-items: baseline;

  .btn-filter {
    margin-left: auto;
    margin-right: 2rem;
    position: relative;

    .filter-options-container {
      position: absolute;
      background-color: $color-grey;
      right: 0;
      padding: 0.5rem;
      margin-right: 0.5rem;

      .options {
        display: flex;

        @include respondMax($mobile-width) {
          display: block; } }

      .filter-option-head {
        margin-bottom: 1rem; }

      // .filter-btn-confirm
      //// margin-right: 0

      .filter-option {
        min-width: 200px;
        margin: 0.5rem;
        background-color: $color-white;
        padding: 1rem;
        border-radius: 4px;
        border-style: solid;
        border-color: $color-grey-dark;
        border-width: 1px;

        .filter-option-row {
          display: flex;
          margin-top: 0.5rem;
          align-items: center;

          .input-calendar-select-wrapper {
            flex-grow: 1; } } } } } }
