.category-container {
  padding-right: 2rem;

  @include respondMax($mobile-width) {
    padding-right: 0; }

  h1 {
    @include respondMax($mobile-width) {
      background-color: $color-grey-yellow; } } }

.category-table-row {
  display: flex;

  @include respondMax($mobile-width) {
    display: block;
    margin-bottom: 2rem; } }

.category-info-content {
  padding-right: 2rem;
  padding-bottom: 0.5rem; }

.category-info.with-text::after {
  content: "";
  display: block;
  background-color: $color-grey;
  height: 2px;
  margin-bottom: 3rem;
  margin-right: 1rem; }

.see-more {
  margin-bottom: 1rem;
  margin-top: 1rem; }

.category-info-content-wrapper {
  max-height: 100px;
  overflow: hidden;

  &.is-open {
    max-height: unset; } }

.category-item {
  min-width: 30%;
  padding: 1rem;
  display: flex;
  border-width: 1px;
  border-color: $color-grey;
  border-style: solid;
  cursor: pointer;
  text-decoration: none;

  @include respondMax($mobile-width) {
    border: 0;
    padding: 0;
    display: inline-block;
    text-align: center;
    min-width: unset;
    vertical-align: top;
    width: 33%; }

  img {
    height: 100%; }

  div {
    display: inline-block;
    margin: auto 0 auto 1rem;

    @include respondMax($mobile-width) {
      display: block;
      margin: 0; } }

  &:hover {
    color: $color-theme;
    border-color: $color-theme;
    text-decoration: none;
    font-weight: bold;

    img {
      filter: $color-theme-filter; } } }


.subcategories-container {
  display: grid;
  grid-template-columns: 33% 33% 34%;
  margin-right: 1rem;
  margin-bottom: 4rem;
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: $color-grey;

  &--tiny {
    border-width: 0 0 0 1px;

    .subcategory-item {
      border-width: 1px 1px 1px 0px; } }

  @include respondMax($mobile-width) {
    display: block;
    margin: 1rem;

    .checkbox {
      vertical-align: middle; }

    a {
      vertical-align: middle; } } }

.subcategory-item {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: inline-flex;
  // width: 33%
  border-color: $color-grey;
  border-style: solid;
  border-width: 0 1px 1px 0;

  @include respondMax($mobile-width) {
    display: block; }

  input[type="checkbox"] {
    margin-right: 0.5rem; }


  &.is-selected {
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    cursor: pointer;
    font-weight: bold;
    border-color: $color-theme;
    color: $color-theme; }

  &:hover {
    color: $color-theme; }

  img {
    margin: 6px; } }

.category-heading {
  padding-top: 1rem;
  text-align: center; }

.category-description {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 0.9rem; }
