header {
  color: $color-black;
  .menu {
    background-color: $color-white; }

  .input-group {
    background-color: $color-grey;
    display: flex;

    input {
      border-width: 0; } }

  .input-search {
    // max-width: 50%
    position: absolute;
    bottom: 0;
    right: 0;

    input {
      min-width: 342px;

      @include respondMax($mobile-width) {
        width: 100%; }

      @include respondMax(1235) {
        min-width: unset; } }

    // +respondMax(1015)
    //   position: relative
 }    //// margin-top: 1rem


  &::after {
    display: block;
    content: "";
    background-color: $color-grey;
    height: 1px; }

  input {
    background-color: $color-grey; } }

.menu-top {
  background-color: $color-theme;
  color: $color-white;
  padding-bottom: 10px;
  padding-top: 10px;
  font-size: 0.8rem;
  margin-bottom: 2rem;

  a {
    color: $color-white; }

  .mid-desktop-hide {
    @include respondMax(1370) {
      display: none; } } }

.menu {
  display: flex; }

.menu-left {
  width: 25%; }

.menu-middle {
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;

  ul.header-list {
    display: inline-flex; } }

.menu-bottom {
  margin-top: 2rem;

  .header-list {
    display: flex; } }

.menu-upper {
  margin-bottom: 1rem;

  .form-group {
    margin-bottom: 0;
    margin-top: 0; } }

.header-list {
  display: inline-block;
  padding-left: 0;
  margin-left: 0;

  &.line-separated {
    li::after {
      opacity: 0.2;
      vertical-align: middle;
      height: 1.5rem !important; } }

  li {
    list-style: none;
    display: inline;
    padding: 0.5rem;
    margin-right: 0.5rem;

    &.is-chosen {
      background-color: $color-white;
      color: $color-white; } }

  .top-filter-item {
    background-color: $color-grey-darker;
    padding-bottom: 1rem;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 2rem;
    border-radius: 4px 4px 0px 0px;
    margin-right: 4px;
    display: inline-block;
    cursor: pointer;
    font-weight: bold;

    &.is-selected {
      background-color: $color-grey; }

    img {
      vertical-align: middle;
      margin-right: 0.5rem; }

    span {
      vertical-align: middle; } }

  &--small {
    margin: 6px; }

  &--right {
    right: 0;

    i {
      font-size: 1.5rem;
      vertical-align: middle;
      padding-right: 6px;
      padding-left: 6px;

      &.font-smaller {
        font-size: 1.2rem; } }

    i.fa-angle-down, i.fa-angle-up {
      font-size: 1.2rem;
      vertical-align: bottom; }

    span {
        vertical-align: middle; }

    .discount {
      display: inline;
      border-style: solid;
      border-color: $color-white;
      border-width: 1px;
      border-radius: 4px;
      padding-bottom: 4px;
      padding-left: 6px;
      padding-right: 6px;
      font-weight: normal;
      margin-left: 1rem; }

    .drop-down {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      color: $color-black;
      z-index: 999;
      background-color: $color-white;
      border-radius: 0 0 4px 4px;
      box-shadow: 0px 2px 10px $color-grey-dark;
      margin-top: 20px;
      padding: 1rem;

      &--right {
        left: -30px; }

      a {
        color: $color-black; }

      .btn {
        margin-left: 0;
        margin-bottom: 1rem; }

      i {
        padding-left: 0; } } } }

// Will not be implemented?
// .app-switch-button
//   position: absolute
//   right: 0
//   bottom: 0
//   background-color: $color-white
//   border-style: solid
//   border-radius: 4px
//   border-width: 1px
//   padding-top: 4px
//   padding-bottom: 4px
//   padding-right: 1rem
//   padding-left: 0.5rem
//   cursor: pointer
//   @include theme('border-color', 'color-dark-inverse')
//   @include theme('color', 'color-dark-inverse')

//   &:hover
//     @include theme('background-color', 'color-dark-inverse')
//     color: $color-white

//     img
//       filter: invert(100%) sepia(41%) saturate(968%) hue-rotate(223deg) brightness(124%) contrast(109%) !important

//   img
//     vertical-align: bottom
//     @include theme('filter', 'color-dark-filter-inverse')


.submenu {
  text-align: left;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: $color-grey;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  border-color: $color-grey-darker; }

.submenu-item {
  display: inline-block;
  width: 120px;
  vertical-align: top;
  margin-right: 1rem;
  text-decoration: none;
  text-align: center;

  &.is-selected {
    color: $color-theme;
    font-weight: bold;

    img {
      filter: $color-theme-filter; } }

  &:hover {
    color: $color-theme;

    img {
      filter: $color-theme-filter; } }

  img {
    margin-bottom: 1rem; } }
