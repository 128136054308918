.dialog-overlay {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  background: rgba(4, 19, 32, .5); // @todo vytvorit barvu v base-sass ?
  overflow: hidden;
  z-index: 9999; } // @todo z-index

.dialog-content {
  background-color: $color-white;
  color: $color-black;
  border-radius: 4px;
  @include respondMax($mobile-width) {
    width: 100%;
    height: 100%; }

  .dialog-content-inner {
    overflow: auto;
    padding: 4rem;
    height: 100%;
    box-sizing: border-box;

    @include respondMax($mobile-width) {
      padding-left: 1rem;
      padding-right: 1rem; }

    @include respondDesktop() {
      // max-width: 600px
      max-height: 98vh;

      &.desktop-overflow-visible {
        overflow: visible; } } }

  &--no-overflow {
    .dialog-content-inner {
      overflow: unset; } }

  &--with-max-width {
    max-width: 800px; }

  &--with-min-width {
    min-width: 400px; }

  &--large {
    .dialog-content-inner {
      max-width: unset;
      max-width: 90vw;

      img {
        max-width: 100%;
        max-height: 100%; } } } }

.password-reset {
  .dialog-content-inner {
    min-width: 300px;
    text-align: left; } }

.dialog-actuality {
  max-width: 1000px;

  h1 {
    text-align: center; }

  .dialog-content-inner {
    max-height: 90vh; } }

.dialog-close {
  font-size: 2rem;
  position: absolute;
  right: 10px;
  top: 0;
  cursor: pointer; }

.info {
  padding-left: 2rem;
  i {
    font-size: 1.5rem;
    position: absolute;
    left: 0;
    top: 3px; } }

.dialog-content-v-90vw {
  width: 90vw;

  @include respondMax($mobile-width) {
    width: 100%;
    max-width: unset !important; } }

.dialog-content.dialog-add-product {
  h1 {
    font-weight: bold; }

  h2 {
    font-size: 1rem; }

  .active-delivery {
    cursor: pointer;
    border-style: solid;
    border-width: 1px;
    border-color: $color-grey-darkest;
    color: $color-grey-darkest;
    border-radius: 4px;
    padding: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    display: inline-block;

    &.is-selected {
      background-color: $color-grey; } } }

.react-calendar {
  width: 100% !important; }
